import React from 'react'
import { Box, Center } from '@mantine/core'

const Player = () => {
  return (
    <Box>
      <Center>
        <audio controls autoPlay>
          <source src="http://s26.myradiostream.com:22266/" type="audio/mp3" />
          Your browser does not support the
        </audio>
      </Center>
    </Box>
  )
}

export default Player
